import clsx from 'clsx';

import { Nullable } from '../../../../shared/types/lang.types';

interface Props {
  title: string;
  description?: string;
  layout?: Nullable<'reverse'>;
  slot?: React.ReactNode;
  className?: string;
}
export const HowItWorksStep: React.FC<Props> = props => {
  const { title, description, layout, slot, className } = props;
  return (
    <li className={clsx('flex justify-center', layout === 'reverse' && 'flex-row-reverse', className)}>
      <div className="mt-6 max-w-[290px] text-left">
        <p className="hero-3 -indent-8">{title}</p>
        {description && <p className="body-3-reg mt-3">{description}</p>}
      </div>
      <div className="w-24" />
      {slot}
    </li>
  );
};
