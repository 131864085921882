import { useTranslations } from 'next-intl';
import * as React from 'react';

import CurrencyIcon from '@icons/i-currency-64.svg';
import { FeatureSectionHorizontal } from '../feature-section-horizontal/feature-section-horizontal';

interface Props {
  className?: string;
}

export const SupportedCurrenciesSection: React.FC<Props> = props => {
  const { className } = props;
  const t = useTranslations();
  return (
    <FeatureSectionHorizontal
      Icon={CurrencyIcon}
      title={t('Marketing.SupportedCurrenciesSection.title')}
      description={t('Marketing.SupportedCurrenciesSection.subTitle')}
      className={className}
    />
  );
};
