import { GameId } from '../../../__generated/types';

export const processGameToColor = (game: GameId): string => {
  switch (game) {
    case GameId.Lol:
    case GameId.Dota2:
      return '#CC5BE9';
    case GameId.Tft:
      return '#FFDB21';
    case GameId.Apex:
    case GameId.Csgo:
    case GameId.Val:
      return '#CC5BE9';
  }
};
