import { useTranslations } from 'next-intl';
import * as React from 'react';

import HowItWorksIcon from '@icons/i-work-64.svg';
import { useSignIn } from '../../../../features/sign-in/model/use-sign-in';
import { getEnv } from '../../../../shared/lib/lang/env-variables/get-env.util';
import { Button } from '../../../../shared/ui/buttons/button/button';
import { FeatureSectionHorizontal } from '../feature-section-horizontal/feature-section-horizontal';

import { HowItWorksStep } from './how-it-works-step';

interface Props {}

export const HowItWorksSection: React.FC<Props> = () => {
  const { signIn } = useSignIn();
  const t = useTranslations();

  return (
    <FeatureSectionHorizontal
      Icon={HowItWorksIcon}
      title={t('HomePage.HowItWorks.title')}
      description={t('HomePage.HowItWorks.subTitle')}
    >
      <ul className="m-0 mt-20 flex list-none flex-col gap-20 p-0">
        <HowItWorksStep
          title={t('HomePage.HowItWorks.step1')}
          description={t('HomePage.HowItWorks.stepDescription1')}
          slot={
            <Button variant="contained" className="mt-12 w-[292px] self-center" onClick={signIn}>
              {t('HomePage.HowItWorks.cta')}
            </Button>
          }
        />
        <HowItWorksStep
          title={t('HomePage.HowItWorks.step2')}
          description={t('HomePage.HowItWorks.stepDescription2')}
          slot={<img src={`${getEnv('NEXT_PUBLIC_CDN_URL')}/how-it-works/step-2.png`} role="presentation" alt="" />}
          layout="reverse"
        />
        <HowItWorksStep
          title={t('HomePage.HowItWorks.step3')}
          description={t('HomePage.HowItWorks.stepDescription3')}
          slot={<img src={`${getEnv('NEXT_PUBLIC_CDN_URL')}/how-it-works/step-3.png`} role="presentation" alt="" />}
        />
        <HowItWorksStep
          title={t('HomePage.HowItWorks.step4')}
          slot={<img src={`${getEnv('NEXT_PUBLIC_CDN_URL')}/how-it-works/step-4.png`} role="presentation" alt="" />}
          layout="reverse"
          className={'items-center'}
        />
      </ul>
    </FeatureSectionHorizontal>
  );
};
