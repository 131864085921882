import clsx from 'clsx';
import * as React from 'react';

interface Props {
  Icon: React.FC<{ className: string }>;
  title: string;
  description: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

export const FeatureSectionHorizontal: React.FC<Props> = props => {
  const { Icon, title, description, children, className } = props;
  return (
    <div className={clsx(className, 'flex flex-col text-center')}>
      {Icon && <Icon className="mx-auto mb-6 size-16" />}
      <h3 className="hero-2 mb-6">{title}</h3>
      <div className="body-3-reg mx-auto max-w-[840px]">{description}</div>
      {children}
    </div>
  );
};
