import clsx from 'clsx';
import * as React from 'react';

import { GameLogoImageFragment } from './__generated/game-logo-image-fragment';

interface Props {
  className?: string;
  game: GameLogoImageFragment;
}

export const GameLogoImage: React.FC<Props> = props => {
  const { className, game } = props;
  return <img alt={game.fullName} className={clsx(className, 'text-white')} src={game.iconUrl} />;
};
