'use client';
import { useTranslations } from 'next-intl';
import * as React from 'react';

import { RefObject } from 'react';
import {
  ChallengeCardWithQr,
  ChallengeCardWithQrT,
} from '../../../../entities/challenge/ui/challenge-card-with-qr/challenge-card-with-qr';
import { useSignIn } from '../../../../features/sign-in/model/use-sign-in';
import { genAccountPath } from '../../../../shared/lib/generators/path.generator';
import { AccountSectionSlug } from '../../../../shared/types/path.types';
import { Button } from '../../../../shared/ui/buttons/button/button';
import { Link } from '../../../../shared/ui/navigation/link/link';
import { Mark } from '../../../../shared/ui/text/mark/mark';

interface Props {
  className?: string;
  ctaAction: 'register' | 'myAccount';
  loading: boolean;
  howItWorksRef: RefObject<HTMLDivElement>;
}

export const WelcomeSection: React.FC<Props> = props => {
  const { className, ctaAction, loading, howItWorksRef } = props;
  const { signIn } = useSignIn();
  const t = useTranslations();
  return (
    <div className={className}>
      <ChallengeCardWithQr
        challenge={fakeChallenge}
        qrUrl={''}
        size="regular"
        className="mx-auto mb-10"
        disableCountUp
      />
      <h1 className="hero-1 mx-auto mb-10 max-w-[1100px] text-center">
        {t.rich('HomePage.Welcome.title', { mark: chunks => <Mark variant="brand">{chunks}</Mark> })}
      </h1>
      <p className="body-3-reg mx-auto mb-10 max-w-[770px]">{t('HomePage.WelcomeSection.description')}</p>

      <div className="flex justify-center gap-3">
        {ctaAction === 'register' && (
          <Button variant="contained" onClick={signIn} loading={loading}>
            {t('Button.joinUs')}
          </Button>
        )}
        {ctaAction === 'myAccount' && (
          <Button variant={'contained'} as={Link} href={genAccountPath(AccountSectionSlug.DASHBOARD)}>
            {t('Button.myAccount')}
          </Button>
        )}
        <Button
          variant="outlined"
          className="w-[292px]"
          onClick={() =>
            howItWorksRef.current?.scrollIntoView({
              behavior: 'smooth',
            })
          }
        >
          {t('Button.howItWorks')}
        </Button>
      </div>
    </div>
  );
};

const fakeChallenge: ChallengeCardWithQrT = {
  name: 'Rock your next game',
  reward: 450,
  contributionsAmount: 24,
  objectives: ['Do not die', 'Pick the champion', 'Help your team'],
};
