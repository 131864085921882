import { useTranslations } from 'next-intl';
import * as React from 'react';

import GameIcon from '@icons/i-game-64.svg';
import { processGameToColor } from '../../../../entities/challenge/lib/process-game-to-color';
import { GameLogoImage } from '../../../../entities/game/ui/game-logo-image/game-logo-image';
import { getEnv } from '../../../../shared/lib/lang/env-variables/get-env.util';
import { isDefAndNotEmpty } from '../../../../shared/types/lang.types';
import { Button } from '../../../../shared/ui/buttons/button/button';
import { Link } from '../../../../shared/ui/navigation/link/link';
import { FeatureSectionHorizontal } from '../feature-section-horizontal/feature-section-horizontal';

import { SupportedGamesSectionFragment } from './__generated/supported-games-section-fragment';

interface Props {
  className?: string;
  supportedGames: SupportedGamesSectionFragment[];
  comingSoonGames: SupportedGamesSectionFragment[] | undefined;
}

export const SupportedGamesSection: React.FC<Props> = props => {
  const { className, supportedGames, comingSoonGames } = props;
  const t = useTranslations();
  return (
    <FeatureSectionHorizontal
      Icon={GameIcon}
      title={t('HomePage.SupportedGames.title')}
      description={t('HomePage.SupportedGames.subTitle')}
      className={className}
    >
      <ul className="mb-16 mt-10 flex items-center justify-center gap-20">
        {supportedGames?.map(game => (
          <li key={game.id} className="relative flex items-center gap-3">
            <GameLogoImage game={game} className="size-10" />
            <p className="body-3-bold">
              {game.fullName}
              <span
                className="absolute -bottom-4 left-0 mt-3 block h-1.5 w-full -skew-x-12"
                style={{ backgroundColor: processGameToColor(game.id) }}
              />
            </p>
          </li>
        ))}
      </ul>
      {isDefAndNotEmpty(comingSoonGames) && (
        <>
          <p className="body-3-reg mb-4">{t('HomePage.SupportedGames.moreGames')}</p>
          <ul className="flex justify-center">
            {comingSoonGames?.map((game, index) => (
              <li key={game.id} className="body-3-bold">
                {game.fullName}
                {index !== comingSoonGames.length - 1 && <span className="mx-4">·</span>}
              </li>
            ))}
          </ul>
        </>
      )}
      <Button
        className="mx-auto mt-10 w-[392px]"
        variant="contained"
        as={Link}
        href={getEnv('NEXT_PUBLIC_NOTIFY_SURVEY_LINK_URL')}
        target="_blank"
        rel="noreferrer"
      >
        {t('Button.notify')}
      </Button>
    </FeatureSectionHorizontal>
  );
};
