import { DeepExtract } from '../../../shared/types/deep-extract.types';
import { Nullable } from '../../../shared/types/lang.types';
import { HomePageQuery } from '../api/__generated/home-page-query';
import { SupportedGamesSectionFragment } from '../ui/supported-games-section/__generated/supported-games-section-fragment';

export const processGames = (
  games: Nullable<DeepExtract<HomePageQuery, ['gamesInfo', 'data']>[]>
): {
  supportedGames: SupportedGamesSectionFragment[];
  comingSoonGames: SupportedGamesSectionFragment[];
} => {
  if (!games) {
    return { supportedGames: [], comingSoonGames: [] };
  }
  const supportedGames: SupportedGamesSectionFragment[] = [];
  const comingSoonGames: SupportedGamesSectionFragment[] = [];
  for (const game of games) {
    if (game.isSupported) {
      supportedGames.push(game.game);
    } else {
      comingSoonGames.push(game.game);
    }
  }
  return { supportedGames, comingSoonGames };
};
