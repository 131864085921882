import { Logger } from '../../../shared/lib/logger/logger';
import { useHomePageQuery } from '../api/__generated/home-page-query';
import { processGames } from '../lib/process-games';
import { SupportedGamesSectionFragment } from '../ui/supported-games-section/__generated/supported-games-section-fragment';

type ReturnT = {
  loading?: boolean;
  isStreamer?: boolean;
  supportedGames?: SupportedGamesSectionFragment[];
  comingSoonGames?: SupportedGamesSectionFragment[];
};

export const useHomePage = (): ReturnT => {
  const { loading, error, data } = useHomePageQuery();
  if (loading) {
    return {
      loading: true,
    };
  }
  // intentionally do not return error message, doesn't matter what happened home page should be displayed
  if (error) {
    Logger.error('[useHomePage]:network error', error);
  }

  if (data?.user.error) {
    Logger.error('[useHomePage]:user error', data.user.error);
  }

  if (data?.gamesInfo.error) {
    Logger.error('[useHomePage]:gamesInfo error', data.gamesInfo.error);
  }
  const { supportedGames, comingSoonGames } = processGames(data?.gamesInfo.data);

  return {
    isStreamer: !!data?.user.data?.streamer?.id,
    supportedGames,
    comingSoonGames,
  };
};
