'use client';
import { clsx } from 'clsx';
import { useTranslations } from 'next-intl';
import * as React from 'react';
import CountUp from 'react-countup';
import { QRCode } from 'react-qrcode-logo';

import { genAppLogoIconUrl } from '../../../../shared/lib/generators/gen-app-logo-icon-url';
import { usePreviousValue } from '../../../../shared/lib/hooks/use-previous-value';

export interface ChallengeCardWithQrT {
  name: string;
  reward: number;
  contributionsAmount: number;
  objectives: string[];
}

interface Props {
  className?: string;
  challenge: ChallengeCardWithQrT;
  qrUrl: string;
  size: 'regular' | 'window';
  disableCountUp?: boolean;
}

export const ChallengeCardWithQr: React.FC<Props> = props => {
  const { className, challenge, qrUrl, size, disableCountUp } = props;
  const { name, reward, objectives, contributionsAmount } = challenge;
  const t = useTranslations();

  const prevRewardValue = usePreviousValue(reward) || 0;
  return (
    <div
      className={clsx(
        className,
        'flex justify-between bg-black-alpha-40',
        size === 'regular' && 'max-w-[490px]',
        size === 'window' && 'w-full'
      )}
    >
      <div
        className={clsx('grow', size === 'regular' && 'max-w-[310px] p-5', size === 'window' && 'max-w-[60vw] p-[4vw]')}
      >
        <p
          className={clsx(
            'truncate',
            size === 'regular' && 'body-3-bold mb-1 ',
            size === 'window' && 'font-inter text-[4vw] '
          )}
        >
          {name}
        </p>
        <div
          className={clsx(
            'flex items-center ',
            size === 'regular' && 'mb-3 gap-4',
            size === 'window' && 'mb-[2vw] gap-[2vw]'
          )}
        >
          {disableCountUp ? (
            <p className={clsx(size === 'regular' && 'hero-3', size === 'window' && 'font-dela text-[6vw]')}>
              ${reward}
            </p>
          ) : (
            <CountUp
              start={+prevRewardValue}
              end={+reward}
              decimal="."
              decimals={2}
              className={clsx(size === 'regular' && 'hero-3', size === 'window' && 'font-dela text-[6vw]')}
              prefix={'$'}
            />
          )}

          <p
            className={clsx(
              'all-caption text-dark-200',
              size === 'regular' && 'mt-1',
              size === 'window' && 'mt-[0.8vw] !text-[2.5vw]'
            )}
          >
            × {t('ChallengeCard.contributionsWithCount', { count: contributionsAmount })}
          </p>
        </div>
        <ul className={clsx('list-disc', size === 'regular' && 'pl-4', size === 'window' && ' pl-[2.4vw] text-[6vw]')}>
          {objectives.map((objective, index) => (
            <li className={clsx('body-4-reg', size === 'window' && '!text-[2vw]')} key={index}>
              <p className="truncate">{objective}</p>
            </li>
          ))}
        </ul>
      </div>
      <div
        className={clsx(
          'shrink-0 border-4 border-red-400 bg-white',
          size === 'regular' && 'w-[180px]',
          size === 'window' && 'size-[35vw]'
        )}
      >
        <QRCode
          value={qrUrl}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          size={256}
          quietZone={2}
          logoImage={genAppLogoIconUrl('app-logo-icon-2')}
          logoHeight={48}
          logoWidth={48}
          removeQrCodeBehindLogo={true}
          logoPadding={8}
        />
      </div>
    </div>
  );
};
