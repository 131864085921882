export type Nullable<T> = T | null | undefined;
export type NString = Nullable<string>;
export type NNumber = Nullable<number>;
export type NBoolean = Nullable<boolean>;
export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};
export type Dict<T> = { [key: string]: Nullable<T> };
export type OptionalRecord<K extends keyof any, V> = Partial<Record<K, V>>;

export type EnumKey = keyof any;

export function isNotNaN(value: any): boolean {
  return !isNaN(value);
}

export function isDef<T>(value: Nullable<T>): value is T {
  return value !== null && value !== undefined;
}

export function isDefAndNotEmpty<T extends ArrayLike<any>>(value: Nullable<T>): value is T {
  return !!(value && value.length);
}

export function isDefAndMoreThanZero(value: Nullable<number>): value is number {
  return isDef(value) && value > 0;
}
