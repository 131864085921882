import * as React from 'react';
import { Link } from '../../navigation/link/link';

interface Props {
  children: React.ReactNode;
  variant: 'highlight' | 'link' | 'brand';
  href?: string;
}
export function Mark(props: Props): React.ReactNode {
  const { children, variant, href } = props;
  switch (variant) {
    case 'highlight':
      return (
        <span className="text-white">
          <b>{children}</b>
        </span>
      );
    case 'link':
      return (
        href && (
          <Link className="text-red-400" href={href}>
            {children}
          </Link>
        )
      );
    case 'brand':
      return <span className="text-[#FF0038]">{children}</span>;
  }
}
