'use client';
import * as React from 'react';

import { isDefAndNotEmpty } from '../../../shared/types/lang.types';
import { useHomePage } from '../model/use-home-page';

import { ChallengesVsDonationsSection } from './challenges-vs-donations-section/challenges-vs-donations-section';
import { HowItWorksSection } from './how-it-works-section/how-it-works-section';
import { SupportedCurrenciesSection } from './supported-currencies-section/supported-currencies-section';
import { SupportedGamesSection } from './supported-games-section/supported-games-section';
import { WelcomeSection } from './welcome-section/welcome-section';

export const HomePage: React.FC = () => {
  const { loading, isStreamer, comingSoonGames, supportedGames } = useHomePage();
  const howItWorksRef = React.useRef<HTMLDivElement>(null);
  return (
    <div className="container">
      <section className={'mb-40'}>
        <WelcomeSection
          loading={!!loading}
          ctaAction={isStreamer ? 'myAccount' : 'register'}
          howItWorksRef={howItWorksRef}
        />
      </section>
      <section id="how-it-works-id" className={'mb-40'} ref={howItWorksRef}>
        <HowItWorksSection />
      </section>
      <section className={'mb-40'}>
        <ChallengesVsDonationsSection />
      </section>
      {isDefAndNotEmpty(supportedGames) && (
        <section className={'mb-40'}>
          <SupportedGamesSection comingSoonGames={comingSoonGames} supportedGames={supportedGames} />
        </section>
      )}
      <section>
        <SupportedCurrenciesSection />
      </section>
    </div>
  );
};
